.main-left-slider {
    min-width: 300px !important;
    padding: 10px;
    border-right: 1px solid #ddd;
}

.main-left-slider .ant-menu {
    border-inline-end:none !important;
}

.login-right-slider {
    min-width: 500px !important;
}

.main-right-slider {
    min-width: 550px !important;
}
.ant-layout-sider-collapsed {
    min-width: 80px !important;
}
body {
    padding: 0;
    margin: 0;
}
.logo-wrapper {
    display: flex;
    /* align-items: center; */
    margin: 10px;
}

.logo-wrapper .logo {
    height: 40px;
    margin-right: 12px;
}
.ant-list-header {
    /* background:#1100e6 !important; */
    /* color: #fff; */
}

.active-action {
    color: rgb(165, 97, 255);
    font-weight: bold;
}

.anticon svg {
    display: inline-block;
    font-size: 18px;
    font-weight: bold !important;
}
.close-icon-15 svg {
    font-size: 15px;
}
.profile-menu .ant-popover-inner{
    margin-right: 8px !important;
}
.activeRun {
    color: blueviolet;
}

.edit-icon,.delete-icon {
    visibility: hidden;
}

.resource-panel:hover .resource-panel-extra .edit-icon, .resource-panel:hover .resource-panel-extra .delete-icon,
.resource-action-panel:hover .resource-action-panel-extra .edit-icon, .resource-action-panel:hover .resource-action-panel-extra .delete-icon,
.element-panel:hover .edit-icon, .element-panel:hover .delete-icon {
    visibility: visible !important;
}

 .ant-collapse-item-active{
    background: #d6e1f5;
}

.ant-collapse-item-active>.ant-collapse-header {
    color: #5c14b3a3 !important;
    font-weight: bold;
}

.anticon-cloud-upload {
    color: #1577ff;
}

.selected-step {
    color: #6d03eee6 !important;
}

.variable-item .close-icon {
    visibility: hidden;
  }
  
  .variable-item:hover .close-icon {
    visibility: visible;
  }